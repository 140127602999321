/* eslint-disable no-undef */
import "jquery/dist/jquery";
import "../css/style.css";
import "../sass/style.scss";
import "materialize-css/dist/css/materialize.min.css";
import "materialize-css/dist/js/materialize.min";
import "../index.html";

import $ from 'jquery';

document.addEventListener("DOMContentLoaded", function () {
  const slider = document.querySelectorAll(".carousel");
  M.Carousel.init(slider, {
    fullWidth: true,
    duration: 100,
    dist: 0,
    numVisible: 1,
    indicators: true,
  });

  const item = document.querySelector(".carousel");
  const i = M.Carousel.getInstance(item);
  setInterval(function () {
    i.next(1);
  }, 5000);

  const down = document.querySelector(".dropdown-trigger");
  const drop = M.Dropdown.init(down, {
    coverTrigger: false,
  });

  M.updateTextFields();

  // //jquery
  $("form").submit(function (event) {
    event.preventDefault();
    $.ajax({
      type: "POST",
      url: "../mailer/smart.php",
      data: $(this).serialize(),
    }).done(function () {
      $(this).find("input").val("");

      $("form").trigger("reset");
    });
    return false;
  });

  // vanillajs
  // function send(event, php) {
  //   console.log("Отправка запроса");
  //   event.preventDefault ? event.preventDefault() : (event.returnValue = false);
  //   const req = new XMLHttpRequest();
  //   req.open("POST", php, true);
  //   req.onload = function () {
  //     if (req.status >= 200 && req.status < 400) {
  //       const json = JSON.parse(this.response);
  //       console.log(json);

  //       // ЗДЕСЬ УКАЗЫВАЕМ ДЕЙСТВИЯ В СЛУЧАЕ УСПЕХА ИЛИ НЕУДАЧИ
  //       if (json.result == "success") {
  //         // Если сообщение отправлено
  //         alert("Сообщение отправлено");
  //       } else {
  //         // Если произошла ошибка
  //         alert("Ошибка. Сообщение не отправлено");
  //       }
  //       // Если не удалось связаться с php файлом
  //     } else {
  //       alert("Ошибка сервера. Номер: " + req.status);
  //     }
  //   };

  //   // Если не удалось отправить запрос. Стоит блок на хостинге
  //   req.onerror = function () {
  //     alert("Ошибка отправки запроса");
  //   };
  //   req.send(new FormData(event.target));
  // }

  // const submitButton = document.querySelector("button");

  // submitButton.onclick(send());

  const slider2 = document.querySelectorAll(".carousel");
  M.Carousel.init(slider2, {
    dist: -50,
    shift: 200,
    numVisible: 3,
    indicators: true,
  });

  const item2 = document.querySelector(".trust .carousel");
  const i2 = M.Carousel.getInstance(item2);
  setInterval(function () {
    i2.next(1);
  }, 3000);

  const slider3 = document.querySelectorAll(".carousel");
  M.Carousel.init(slider3, {
    dist: -100,
    shift: 200,
    numVisible: 3,
    indicators: false,
  });

  const item3 = document.querySelector(".products .carousel");
  const i3 = M.Carousel.getInstance(item3);
  setInterval(function () {
    i3.next(1);
  }, 4000);

  const serts = document.querySelectorAll(".materialboxed");
  const sert = M.Materialbox.init(serts, {});

  var roadMaps = document.querySelectorAll(".modal");
  var roadMap = M.Modal.init(roadMaps, {});
});
